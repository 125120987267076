<template>
  <div class="Page-Wrapper">
    <SortingWithFiltersPreset
      :title="'Администрирование товаров'"
      :originalArray="getProducts"
      :searchAttribures="filters.searchInProperties"
      :setNewArrayCallback="handleSearchFilterSortingObject"
      :sortMethodCallback="sortingMethods"
      :filtersButtons="filters.filtersButtons"
      :sortOptions="filters.sortingOptions"
      :callbackForFilterButton="handleFilterButton"
      :filterButtonModeServer="true"
      :setFiltersData="{
        button: filters.selectedButton,
        search: filters.selectedSearch,
        sorting: filters.selectedSorting,
      }"
      :postFiltersDataChanges="handleFilterChanges"
      :key="'SortingFiltersSearch-' + products_hash + getProducts"
    >
      <template v-slot:content>
        <span
          class="Offer-Button-Next"
          style="width: fit-content;"
          @click="
            () => {
              show_creating_modal = true;
            }
          "
          >Создать новый товар</span
        >
      </template>
    </SortingWithFiltersPreset>

    <div class="Products-Block" :key="'product-block' + products_filtered_hash">
      <div
        v-for="(p, pindx) in store.products_filtered"
        :key="pindx + 'product-' + p.id"
        :class="{
          'Product-Card': true,
          noselect: true,
          'No-BG-IMG': p?.ProductImages?.length != 0,
        }"
      >
        <span class="Product-Title">{{ p.name }}</span>

        <div class="Product-Bottom-Block">
          <div class="Product-Tips">
            <span
              v-for="(tip, tindx) in getTipsArray(p)"
              :key="tindx + 'tip' + tip.text"
              :class="tip.classessList"
              >{{ tip.text }}</span
            >
          </div>

          <span
            class="Product-Transfer-Button"
            @click="
              () => {
                openModal(p);
              }
            "
            >Редактировать</span
          >
          <span
            class="Product-Transfer-Button"
            @click="
              () => {
                if (p.archieve) {
                  setArchieveStatus(p.id, false);
                } else {
                  setArchieveStatus(p.id, true);
                }
              }
            "
            >{{ p.archieve ? "Убрать из архива" : "В архив" }}
          </span>
        </div>

        <div
          v-if="p.ProductImages.length != 0"
          class="Product-Background-Image"
          :style="
            `
              background: url('${p.ProductImages?.[0]?.url_file}');
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;`
          "
        ></div>
      </div>
    </div>

    <NewStyledModal
      class="Styled-Modal"
      :showModal="show_modal"
      :hideModal="
        () => {
          show_modal = false;
          product_selected = null;
        }
      "
      :key="show_modal"
    >
      <template v-slot:ModalContent>
        <div class="Offer-Modal" v-if="product_selected != null">
          <span class="Offer-Modal-Title">
            Редактирование товара
          </span>

          <div class="Offer-Modal-Content">
            <div class="Input-Floating-Label">
              <input
                class="Input-styled"
                type="text"
                placeholder="Введите название товара"
                autocomplete="off"
                maxlength="64"
                v-model="product_selected.name"
              />
              <span class="Input-Placeholder noselect">Название</span>
              <span class="Input-Text-Length noselect">{{
                64 - product_selected.name.length
              }}</span>
            </div>

            <div class="Input-Floating-Label">
              <textarea
                class="Input-styled"
                placeholder="Введите описание"
                autocomplete="off"
                maxlength="512"
                v-model="product_selected.desc"
              />
              <span class="Input-Placeholder noselect">Описание</span>
              <span class="Input-Text-Length noselect">{{
                512 -
                  (product_selected?.desc == null
                    ? 0
                    : product_selected?.desc?.length)
              }}</span>
            </div>

            <div class="Input-Floating-Label">
              <input
                class="Input-styled"
                type="text"
                placeholder="Введите стоимость товара"
                autocomplete="off"
                maxlength="7"
                v-model="product_selected.price"
              />
              <span class="Input-Placeholder noselect">Стоимость</span>
            </div>

            <div class="Input-Floating-Label">
              <input
                class="Input-styled"
                type="text"
                placeholder="Введите лимит на покупки"
                autocomplete="off"
                maxlength="3"
                v-model="product_selected.limited_quantity"
              />
              <span class="Input-Placeholder noselect"
                >Лимит товара на человека</span
              >
            </div>

            <div class="Input-Floating-Label">
              <input
                class="Input-styled"
                type="text"
                placeholder="Введите остаток товара"
                autocomplete="off"
                maxlength="7"
                v-model="product_selected.available_amount"
              />
              <span class="Input-Placeholder noselect">Остаток товара</span>
            </div>

            <div class="Input-Floating-Label">
              <select class="Input-styled" v-model="product_selected.type">
                <option
                  v-for="(f, findx) in store.product_types"
                  :key="f + findx"
                  :value="f"
                  >{{ f }}</option
                >
              </select>
              <span class="Input-Placeholder noselect">Тип</span>
            </div>

            <div class="Input-Floating-Label">
              <select
                class="Input-styled"
                v-model="product_selected.product_region"
              >
                <option :value="null">Регион не требуется</option>
                <option
                  v-for="(f, findx) in regions"
                  :key="f + findx"
                  :value="f.id"
                  >{{ f.name }}</option
                >
              </select>
              <span class="Input-Placeholder noselect">Регион товара</span>
            </div>

            <div class="Input-Floating-Label">
              <div class="checkbox_agreement">
                <input
                  type="checkbox"
                  id="advert_checkbox"
                  name="advert_checkbox"
                  v-model="product_selected.openSoon"
                  style="width: 24.4px !important;"
                />
                <label for="advert_checkbox">Скоро откроется</label>
              </div>
            </div>

            <div
              class="dropzone-container"
              :style="isDragging && 'border-color: green;'"
              @dragover="dragover"
              @dragleave="dragleave"
              @drop="drop"
            >
              <input
                type="file"
                multiple
                name="file"
                id="fileInput"
                class="hidden-input"
                @change="onChange"
                ref="file"
                accept=".pdf,.jpg,.jpeg,.png"
              />

              <label for="fileInput" class="file-label">
                <div class="noselect">
                  Нажмите на эту область или перетащите в нее материалы
                </div>
              </label>
            </div>

            <div
              class="preview-container"
              :key="
                'product-images' +
                  getProductImages +
                  product_selected_images_hash +
                  product_selected?.ProductImages?.length
              "
              v-if="files.length >= 1 || getProductImages?.length >= 1"
            >
              <div
                v-for="file in getProductImages"
                :key="file.url_file"
                class="preview-card"
              >
                <div
                  class="Preview-Img"
                  :style="getStyleFromUploadedFile(file.url_file)"
                >
                  <div
                    class="Remove-Button"
                    @click="
                      () => {
                        deleteMaterial(file.id);
                      }
                    "
                  ></div>
                </div>
              </div>

              <div v-for="file in files" :key="file.name" class="preview-card">
                <div class="Preview-Img" :style="generateURL(file)">
                  <div
                    class="Remove-Button"
                    @click="remove(files.indexOf(file))"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="Offer-Modal-Buttons noselect">
            <span
              :class="{
                'Offer-Button-Next': true,
                'Not-Available': block_save_button,
              }"
              :key="'block_save_button' + block_save_button"
              @click="
                () => {
                  if (!block_save_button) {
                    saveChanges();
                  }
                }
              "
              >Сохранить</span
            >
            <span
              class="Offer-Button-Prev"
              @click="
                () => {
                  closeModal();
                }
              "
              >Отмена</span
            >
          </div>
        </div>
      </template>
    </NewStyledModal>

    <NewStyledModal
      class="Styled-Modal"
      :showModal="show_creating_modal"
      :hideModal="resetCreatingModal"
      :key="show_creating_modal"
    >
      <template v-slot:ModalContent>
        <div class="Offer-Modal" v-if="product_creating != null">
          <span class="Offer-Modal-Title">
            Создание товара
          </span>

          <div class="Offer-Modal-Content">
            <div class="Input-Floating-Label">
              <input
                class="Input-styled"
                type="text"
                placeholder="Введите название товара"
                autocomplete="off"
                maxlength="64"
                v-model="product_creating.name"
              />
              <span class="Input-Placeholder noselect">Название</span>
              <span class="Input-Text-Length noselect">{{
                64 - product_creating.name.length
              }}</span>
            </div>

            <div class="Input-Floating-Label">
              <textarea
                class="Input-styled"
                placeholder="Введите описание"
                autocomplete="off"
                maxlength="512"
                v-model="product_creating.desc"
              />
              <span class="Input-Placeholder noselect">Описание</span>
              <span class="Input-Text-Length noselect">{{
                512 -
                  (product_creating?.desc == null
                    ? 0
                    : product_creating?.desc?.length)
              }}</span>
            </div>

            <div class="Input-Floating-Label">
              <input
                class="Input-styled"
                type="text"
                placeholder="Введите стоимость товара"
                autocomplete="off"
                maxlength="7"
                v-model="product_creating.price"
              />
              <span class="Input-Placeholder noselect">Стоимость</span>
            </div>

            <div class="Input-Floating-Label">
              <input
                class="Input-styled"
                type="text"
                placeholder="Введите лимит на покупки"
                autocomplete="off"
                maxlength="3"
                v-model="product_creating.limited_quantity"
              />
              <span class="Input-Placeholder noselect"
                >Лимит товара на человека</span
              >
            </div>

            <div class="Input-Floating-Label">
              <input
                class="Input-styled"
                type="text"
                placeholder="Введите остаток товара"
                autocomplete="off"
                maxlength="7"
                v-model="product_creating.available_amount"
              />
              <span class="Input-Placeholder noselect">Остаток товара</span>
            </div>

            <div class="Input-Floating-Label">
              <select class="Input-styled" v-model="product_creating.type">
                <option
                  v-for="(f, findx) in store.product_types"
                  :key="f + findx"
                  :value="f"
                  >{{ f }}</option
                >
              </select>
              <span class="Input-Placeholder noselect">Тип</span>
            </div>

            <div class="Input-Floating-Label">
              <select
                class="Input-styled"
                v-model="product_creating.product_region"
              >
                <option :value="null">Регион не требуется</option>
                <option
                  v-for="(f, findx) in regions"
                  :key="f + findx"
                  :value="f.id"
                  >{{ f.name }}</option
                >
              </select>
              <span class="Input-Placeholder noselect">Регион товара</span>
            </div>

            <div class="Input-Floating-Label">
              <div class="checkbox_agreement">
                <input
                  type="checkbox"
                  id="advert_checkbox"
                  name="advert_checkbox"
                  v-model="product_creating.openSoon"
                  style="width: 24.4px !important;"
                />
                <label for="advert_checkbox">Скоро откроется</label>
              </div>
            </div>

            <div
              class="dropzone-container"
              :style="isDragging && 'border-color: green;'"
              @dragover="dragover"
              @dragleave="dragleave"
              @drop="drop"
            >
              <input
                type="file"
                multiple
                name="file"
                id="fileInput"
                class="hidden-input"
                @change="onChange"
                ref="file"
                accept=".pdf,.jpg,.jpeg,.png"
              />

              <label for="fileInput" class="file-label">
                <div class="noselect">
                  Нажмите на эту область или перетащите в нее материалы
                </div>
              </label>
            </div>

            <div
              class="preview-container"
              :key="
                'product-images' +
                  getProductImages +
                  product_selected_images_hash +
                  product_selected?.ProductImages?.length
              "
              v-if="files.length >= 1 || getProductImages?.length >= 1"
            >
              <div v-for="file in files" :key="file.name" class="preview-card">
                <div class="Preview-Img" :style="generateURL(file)">
                  <div
                    class="Remove-Button"
                    @click="remove(files.indexOf(file))"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="Offer-Modal-Buttons noselect">
            <span
              :class="{
                'Offer-Button-Next': true,
                'Not-Available': true,
              }"
              :key="'block_save_button' + block_save_button"
              @click="createProduct"
              >Добавить</span
            >
            <span class="Offer-Button-Prev" @click="resetCreatingModal"
              >Отмена</span
            >
          </div>
        </div>
      </template>
    </NewStyledModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import {
  adminGetProductsByFilters,
  setArchieveState,
  deleteProductMaterial,
  uploadProductMaterials,
  updateProduct,
  createProduct,
} from "@/api/store.js";

import { getRegionAndUniverList } from "@/api/user.js";

import SortingWithFiltersPreset from "@/components/CustomElements/SortingWithFiltersPreset.vue";
import NewStyledModal from "@/components/CustomElements/NewStyledModal.vue";

export default {
  components: { SortingWithFiltersPreset, NewStyledModal },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),

    products_hash() {
      let a = this.store.products.map(
        (x) => `${x.id}_${x?.ProductImages?.length}_${x?.archieve}`
      );
      return `${this.store.products?.length}-${a?.join("-")}`;
    },

    products_filtered_hash() {
      let a = this.store.products_filtered.map(
        (x) => `${x.id}_${x?.ProductImages?.length}_${x?.archieve}`
      );
      return `${this.store.products_filtered?.length}-${a?.join("-")}`;
    },

    getProductImages() {
      return this.product_selected?.ProductImages;
    },

    getProducts() {
      return this.store.products;
    },

    product_selected_images_hash() {
      let a = this.product_selected?.ProductImages.map((x) => x.id);
      return `${this.product_selected?.ProductImages?.length}-${a?.join("-")}`;
    },
  },
  data() {
    return {
      show_modal: false,
      show_creating_modal: false,

      filters: {
        sortingOptions: [
          {
            text: "Сортировка по умолчанию",
            val: "default",
          },
        ],
        searchInProperties: ["name", "desc"],
        filtersButtons: [
          {
            text: "Доступные",
            val: "available",
          },
          {
            text: "Архив",
            val: "show-only-archieve",
          },
        ],
        selectedButton: "",
        selectedSorting: "default",
        selectedSearch: "",
      },

      store: {
        offset: 0,

        products: [],
        products_filtered: [],
        products_counter: 0,

        product_types: ["Физический", "Дистанционный"],
      },

      product_selected: null,
      isDragging: false,
      files: [],

      product_creating: {
        name: "",
        type: "Физический",
        desc: "",
        price: 1,
        available_amount: 0,
        limited_quantity: 0,
        archieve: 0,
        openSoon: false,
        product_region: null,
      },

      regions: [],

      block_save_button: false,
    };
  },
  async created() {
    await this.initialization();
  },
  methods: {
    async initialization() {
      await getRegionAndUniverList().then((response) => {
        this.regions = response.data.filter((x) => x.name !== "Другое");
      });

      let _store_products = await adminGetProductsByFilters({
        selected_button: this.filters.selectedButton,
      });
      this.store.products = _store_products.data;

      if (this.product_selected != null) {
        let a = this.store.products.find(
          (x) => x.id === this.product_selected.id
        );
        console.log(a);
        this.product_selected = { ...a };
      }
    },

    getTipsArray(product) {
      let array = [];

      if (product.openSoon) {
        array.push({
          text: "Скоро откроется",
          classessList: "Product-Tip Is-Recommended",
        });

        return array;
      }

      if (product.archieve) {
        array.push({
          text: "В архиве",
          classessList: "Product-Tip Is-Archieve",
        });

        return array;
      }

      if (product.available_amount === 0) {
        array.push({
          text: "Товар закончился",
          classessList: "Product-Tip Not-Enough-Coins",
        });

        return array;
      }

      if (product.available_amount <= 5 && product.available_amount !== 0) {
        array.push({
          text: "Скоро закончится",
          classessList: "Product-Tip Few-Products",
        });
      }
      return array;
    },
    isNewProduct(product_created) {
      const specifiedDate = new Date(product_created);
      const currentDate = new Date();

      const difference = currentDate.getTime() - specifiedDate.getTime();

      return difference < 5 * 24 * 60 * 60 * 1000;
    },

    async setArchieveStatus(id, isArchieve) {
      await setArchieveState({
        product_id: id,
        archieve: isArchieve,
      });

      await this.initialization();
    },

    openModal(product) {
      this.product_selected = product;
      this.show_modal = true;
    },

    sortingMethods(sort_name, array) {
      return array;
    },
    handleSearchFilterSortingObject(Array) {
      console.log(Array);
      this.store.products_filtered = Array;
    },
    handleFilterButton(val) {
      this.filters.selectedButton = val;
      this.initialization();
    },
    handleFilterChanges(obj) {
      this.filters.selectedButton = obj.button;
      this.filters.selectedSearch = obj.search;
      this.filters.selectedSorting = obj.sorting;
    },

    onChange() {
      const self = this;
      let incomingFiles = Array.from(this.$refs.file.files);
      const fileExist = self.files.some((r) =>
        incomingFiles.some(
          (file) => file.name === r.name && file.size === r.size
        )
      );

      console.log(this.$refs.file.files);

      if (fileExist) {
        self.showMessage = true;
        alert("Данные файлы уже загружены.");
      } else {
        self.files.push(...incomingFiles);
      }
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    remove(i) {
      this.files.splice(i, 1);
    },
    generateURL(file) {
      let fileSrc = URL.createObjectURL(file);
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
      }, 1000);
      let style = `
      background: url('${fileSrc}');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: #302E51;
      `;
      return style;
    },
    getStyleFromUploadedFile(mat_url) {
      let style = `
      background: url('${mat_url}');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: #302E51;
      `;
      return style;
    },

    closeModal() {
      this.show_modal = false;
      this.files = [];
      this.product_selected = null;
      this.block_save_button = false;
    },

    async deleteMaterial(mat_id) {
      await deleteProductMaterial(mat_id);
      await this.initialization();
    },
    async uploadMaterialsInSprint() {
      let formData = new FormData();

      this.files.forEach((photo) => {
        if (photo) {
          formData.append(photo.name, photo);
        }
      });

      await uploadProductMaterials(this.product_selected.id, formData);

      this.files = [];
      return 0;
    },

    async createProduct() {
      let formData = new FormData();

      this.files.forEach((photo) => {
        if (photo) {
          formData.append(photo.name, photo);
        }
      });

      formData.append("req_data", JSON.stringify(this.product_creating));

      await createProduct(formData);

      this.initialization();
      this.resetCreatingModal();
      this.files = [];
    },

    async saveChanges() {
      this.block_save_button = true;
      if (this.files.length >= 1) {
        await this.uploadMaterialsInSprint();
      }

      await updateProduct(this.product_selected.id, {
        name: this.product_selected.name,
        desc: this.product_selected.desc,
        price: this.product_selected.price,
        available_amount: this.product_selected.available_amount,
        limited_quantity: this.product_selected.limited_quantity,
        type: this.product_selected.type,
        openSoon: this.product_selected.openSoon,
        product_region: this.product_selected.product_region,
      });

      await this.initialization();
      this.block_save_button = false;
      this.show_modal = false;
    },

    resetCreatingModal() {
      this.show_creating_modal = false;

      this.files = [];

      this.product_creating = {
        name: "",
        type: "Физический",
        desc: "",
        price: 1,
        available_amount: 0,
        limited_quantity: 0,
        archieve: 0,
      };
    },
  },
};
</script>

<style scoped>
select.Input-styled::-ms-expand {
  display: none;
}
select.Input-styled option {
  background-color: white;
  color: black;
}

.dropzone-container {
  position: relative;
  display: block;

  width: 320px;
  height: fit-content;

  background: transparent;
  border: 1px solid;
  border-color: #413e74;

  border-radius: 10px;
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}

.file-label {
  position: relative;
  display: block;

  padding: 42px 52px;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 400;
  line-height: 23.25px;
  text-align: center;

  cursor: pointer;

  color: #e8e7ec;
}
.preview-container {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;

  width: 100%;
  height: fit-content;
}
.preview-card {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: center;
  gap: 4px;

  width: calc((100% - 30px) / 4);
  height: fit-content;
}

.preview-card > span {
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 400;
  line-height: 23.25px;
  text-align: center;

  word-break: break-word;

  color: #e8e7ec;
}
.Preview-Img {
  position: relative;
  display: block;

  width: 100%;
  height: auto;

  aspect-ratio: 1 / 1;

  border-radius: 10px;
}
.Remove-Button {
  position: absolute;
  display: block;

  cursor: pointer;

  top: 0;
  right: -5px;

  width: 5px;
  height: 5px;

  background: url("./../../assets/img/profile/RemoveButtonMaterials.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.Page-Wrapper {
  position: relative;

  padding-top: 60px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  padding: 50px 8vw;

  width: 100%;
  min-height: 100vh;
  height: fit-content;

  color: white;
}

.Products-Block {
  position: relative;
  display: grid;

  grid-template-columns: repeat(5, calc((100% - 24px * 4) / 5));
  gap: 24px;

  width: 100%;
  height: fit-content;
}

.Product-Card {
  position: relative;
  display: flex;

  padding: 20px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 100%;
  height: auto;

  min-height: 300px;
  /* aspect-ratio: 0.88 / 1; */

  border-radius: 12px;

  background: url("./../../assets/img/Admin/LogoBG.png");
  background-position: 70% 0%;
  background-size: 60%;
  background-repeat: no-repeat;
  background-color: #1e1b4a;

  transition-timing-function: ease-in;
  transition: 0.2s;

  overflow: hidden;
}
.Product-Card:hover {
  background-color: #27235c;
}

.Product-Title {
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #ffffffe5;
}
.Product-Bottom-Block {
  position: relative;
  display: flex;

  margin-top: auto;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 100%;
  height: fit-content;
}
.Product-Tips {
  position: relative;
  display: flex;

  margin-bottom: 4px;

  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 4px;

  width: 100%;
  height: fit-content;
}
.Product-Tip {
  position: relative;
  display: block;

  padding: 4px 8px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  border-radius: 4px;
}
.Product-Tip.Not-Enough-Coins {
  color: #ed254e;
  border: 1px solid #ed254e;
  background-color: transparent;
}
.Product-Tip.Is-New {
  color: #25ed69;
  background-color: #25ed6926;
}
.Product-Tip.Is-Recommended {
  color: #e924bf;
  background-color: #e924bf26;
}
.Product-Tip.Few-Products {
  color: #f9dc5c;
  background-color: #f9dc5c26;
}
.Product-Tip.Is-Archieve {
  color: #d3caffb2;
  background-color: #27235c;
}

.Product-Transfer-Button {
  position: relative;
  display: block;
  cursor: pointer;

  padding: 8px 0px;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;

  border-radius: 4px;

  color: #ffffffe5;
  background-color: #656bff;

  transition-timing-function: ease-in;
  transition: 0.2s;

  opacity: 1;
}
.Product-Transfer-Button:hover {
  background-color: #656bffe5;
}

.Product-Card.No-BG-IMG {
  background: #1e1b4a;
  background-color: #1e1b4a;
}
.Product-Card > *:not(.Product-Background-Image) {
  z-index: 5;
}
.Product-Card:hover > .Product-Background-Image {
  opacity: 0.05;
}
.Product-Background-Image {
  position: absolute;
  display: block;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  transition-timing-function: ease-in;
  transition: 0.2s;

  border-radius: 12px;

  filter: blur(2px);
  opacity: 0.1;
  z-index: 3;
}
</style>

<style scoped>
.Styled-Modal:deep(.SendRequestModal) {
  min-width: 0;
  width: fit-content;
  background: #1e1b4a;
}

.Modal-Card-Wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;

  width: fit-content;
  height: fit-content;
}
.Product-Card.Modal-Card {
  position: relative;

  min-width: 0;
  width: 320px;
  height: auto;
  padding: 0px;

  border-radius: 0px;
  background: transparent;
  background-color: transparent;
}
.Product-Card.Modal-Card .Product-Bottom-Block {
  margin-top: 8px;
}
.Product-Card.Modal-Card .Product-Desc {
  display: block;
  color: #d3caffb2;
}

.Offer-Modal {
  position: relative;
  display: flex;

  padding-top: 12px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;

  width: fit-content;
  height: fit-content;
}

.Offer-Modal-Title {
  position: relative;
  display: block;

  min-width: 320px;
  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;
}
.Offer-Modal-Content {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  min-width: 320px;
  width: fit-content;
  height: fit-content;
}

.Input-Floating-Label {
  position: relative;
  display: block;

  min-width: 320px;
  width: fit-content;
  height: fit-content;
}

.Input-styled {
  position: relative;
  display: block;

  padding: 12px 8px;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  background-color: transparent;

  outline: 0;
  outline-offset: 0;

  border-radius: 4px;
  border: 1px solid #d3caff40;
}
textarea.Input-styled {
  min-height: 240px;
}

.Input-styled::placeholder {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #d3caff73;
}
.Input-styled:focus {
  border-color: #656bff;
}
.Input-styled.Input-highligt-error {
  border-color: #ed254e;
}
.Input-styled.Input-highligt-error:focus + .Input-Placeholder,
.Input-styled.Input-highligt-error + .Input-Placeholder {
  color: #ed254e;
}

.Input-Placeholder {
  position: absolute;
  display: block;

  top: 0;
  left: 18px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  transform: translate(0px, -50%);

  transition-timing-function: ease-in;
  transition: 0.2s;

  color: #d3caff4d;
  background: #1e1b4a;
  padding: 0% 2px;
}
.Input-styled:focus + .Input-Placeholder {
  color: #656bff;
}

.Input-Text-Length {
  position: absolute;
  display: block;

  bottom: 0;
  right: 18px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  transform: translate(0px, 50%);

  transition-timing-function: ease-in;
  transition: 0.2s;

  color: #d3caff4d;
  background: #1e1b4a;
  padding: 0% 2px;
}
.Input-styled:focus + .Input-Text-Length {
  color: #656bff;
}

.Offer-Modal-Buttons {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;

  width: 320px;
  height: fit-content;
}

.Offer-Button-Next {
  position: relative;
  display: block;
  cursor: pointer;

  padding: 12px 16px;

  width: 100%;
  height: fit-content;

  flex-shrink: 1;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  border-radius: 4px;
  background-color: #656bff;
}
.Offer-Button-Prev {
  position: relative;
  display: block;
  cursor: pointer;

  padding: 12px 16px;

  width: fit-content;
  height: fit-content;

  flex-shrink: 0;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  border-radius: 4px;
  background-color: #656bff40;
}
.Offer-Button-Prev:hover {
  background-color: rgba(101, 106, 255, 0.144);
}

.Offer-Text-Before-Confirm {
  position: relative;
  display: block;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #d3caffb2;
}
.Offer-Text-Before-Confirm .Text-Before-Confirm-Important {
  color: #ffffffe5;
}

.Offer-Modal-Buttons-Child-Wrapper {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 320px;
  height: fit-content;
}

.Offer-Text-Balance-After {
  position: relative;
  display: block;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: uppercase;

  color: #d3caff4d;
}
.Offer-Text-Error {
  position: relative;
  display: block;

  margin-top: 12px;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: none;
  color: #ed254e;
}
</style>

<style scoped>
.HorizontalBlocks {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
}

.checkbox_agreement {
  color: #fff;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-wrap: nowrap;
  margin-top: 0.3rem;
  align-items: center;
}

.checkbox_agreement a {
  font-weight: 500;
  color: #6c59f7;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: transparent;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.1em solid #6e6a9a;
  border-radius: 4px;
  padding: 0.7rem;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;

  margin-right: 1rem;
}

input[type="checkbox"]:checked {
  background-image: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #fff;

  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}
</style>
